.custom_tooltip {
  background: #b5b5b5 0% 0% no-repeat padding-box;
  color: white;
  padding: 0 4px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
#membership-tooltip {
  vertical-align: middle !important;
  margin-left: 7px !important;
  font-size: 16px !important;
}

#filter-tooltip {
  border-radius: 0.25rem;
  @media (min-width: 992px) {
    height: 92% !important;
  }
}

.custom_tooltip_wrap .tooltip-inner {
  background: transparent linear-gradient(180deg, #64d4ead5 0%, #0ab0c9 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #0000007b;
  opacity: 1;
  width: 330px !important;
  max-width: 330px;
  padding: 10px;

  h1 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.custom_tooltip_wrap .bs-tooltip-right .arrow::before {
  border-right-color: #64d4ead5 !important;
}

.custom_tooltip_wrap .bs-tooltip-left .arrow::before {
  border-left-color: #64d4ead5 !important;
}

.custom_tooltip_wrap .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #64d4ead5 !important;
}

.custom_tooltip_wrap .bs-tooltip-top .arrow::before {
  border-top-color: #64d4ead5 !important;
}

.resources_tooltip {
  color: #28b1c9;
  padding: 0px 2px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 9px;
  cursor: pointer;
  border: 0.5px solid #28b1c9;
  margin-left: 3px;
  padding-left: 1px;
}

.resources_tooltip .tooltiptext {
  visibility: hidden;
  width: 190px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding-left: 6px;
  font-size: 12px;
  margin-left: 10px;
  background: transparent linear-gradient(90deg, #8c919176 0%, #79797981 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.resources_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #8c919176 transparent transparent;
}

.resources_tooltip:hover .tooltiptext {
  visibility: visible;
}

.fc-event {
  cursor: pointer;
}

.gray_tooltip {
  .tooltip-inner {
    background: transparent linear-gradient(90deg, #8c9191 0%, #79797981 100%)
      0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #0000001f;
    opacity: 1;
  }

  .arrow::before {
    border-top-color: #8c9191 !important;
    border-bottom-color: #8c9191 !important;
  }
}
