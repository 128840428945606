.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none !important;
}

#nav-profile > a {
  padding: 0 0 0 1em;
}

#nav-location {
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  & > a {
    color: $black;
  }
}

// #mobile-nav-bottom > .nav-link {
//   color: var(--bs-primary);

//   &.active {
//     color: var(--bs-primary);
//   }
// }

// .nav-mobile-item {
//   list-style: none;

//   &:active {
//     color: $black;
//   }
// }

// ----------------
// * FullCalendar *
// ----------------

// customize nav menu of the calendar view of fullcalendar
.custom-calendar > * > .fc-toolbar.fc-header-toolbar {
  @extend .row;

  & > .fc-left {
    @extend .col-12;
    @extend .col-md-4;
    margin-bottom: 0.5em;
  }

  & > .fc-center {
    @extend .col-12;
    @extend .col-md-4;
    @extend .d-flex;
    @extend .justify-content-end;
    font-size: small;
    margin-bottom: 0.5em;
  }

  & > .fc-right {
    @extend .col-12;
    @extend .col-md-4;
    @extend .d-flex;
    @extend .justify-content-end;
  }
}

// fix sticky-left position of a month on Timeline view
.fc-cell-text.fc-sticky {
  left: 10px;
}

.custom-timeline > * > .fc-toolbar.fc-header-toolbar {
  @extend .row;

  & > .fc-left {
    @extend .col;
    font-size: small;
  }

  & > .fc-center {
    @extend .col;
  }

  & > .fc-right {
    @extend .col;
    @extend .d-flex;
    @extend .justify-content-end;
  }
}

.custom-timeline > * > .fc-toolbar.fc-header-toolbar {
  @extend .row;

  & > .fc-left {
    @extend .col;
    font-size: small;
  }

  & > .fc-center {
    @extend .col;
  }

  & > .fc-right {
    @extend .col;
    @extend .d-flex;
    @extend .justify-content-end;
  }
}

// Cookie consent banner
.react-cookie-law-dialog .react-cookie-law-select-pane span {
  color: black !important;
}

.membership-list-bdg-btn {
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  vertical-align: middle;
}

.cursor-not-allowed {
  cursor: not-allowed;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}

// -----------------
// *   Floorplan   *
// -----------------
.zone-label {
  font-weight: normal;
  cursor: pointer;
}
.mini-booking-info-chart-card > .row > .col {
  padding: 1em 0;
  cursor: pointer;
  border-radius: 25%;
}
.mini-booking-info-chart-card * .col:hover {
  background-color: $primary;
}
// .mini-booking-info-chart-card > .row > .is-occupied {
.mini-booking-info-chart-card * .is-occupied {
  background-color: $red;
}

// ---------------------------------
// *   Custom utility classnames   *
// ---------------------------------
.i-color-pink {
  color: $pink2;
}
.i-color-blue {
  color: $cyan;
}
.i-bottom-border-color {
  border-bottom: 3px solid var(--navbar-bottom-borderline-color);
}
.bg-color-transparent {
  background-color: transparent !important;
}
// TODO: this will break in Bootstrap 5.0
// use   .was-validated .custom-select:valid
.i-input-no-validation-icon.form-control:valid,
.i-input-no-validation-icon.form-control:invalid,
.i-input-no-validation-icon.form-control.is-valid,
.i-input-no-validation-icon.form-control.is-invalid {
  background-image: none;
  border-color: inherit;
  padding: 0.375rem 0.75rem; // <- set back the original before-icon size
}
.i-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.i-flex-center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.i-flex-center-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.i-flex-center-top {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.i-flex-center-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.i-flex-top-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.i-flex-top-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.i-flex-bottom-left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.i-flex-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.booking-summary-item {
  margin-bottom: 0.45rem !important;
  color: #909699;
  border-bottom: 1px solid #f4f0f0;
  padding: 5px 0;
}
