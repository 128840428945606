.fc-time-grid-event.fc-event {
  @for $i from 1 through 48 {
    &.buffer-before-#{$i}::before {
      content: "buffer";
      position: absolute;
      background-color: var(--booking-buffer-bg-color);
      width: 100%;
      left: 0;
      height: (22px * $i);
      top: -(22px * $i);
    }
  }

  @for $i from 1 through 48 {
    &.buffer-after-#{$i}::after {
      content: "buffer";
      position: absolute;
      background-color: var(--booking-buffer-bg-color);
      width: 100%;
      left: 0;
      height: (22px * $i);
      bottom: -(22px * $i);
    }
  }
}
