:root {
  // Bootstrap customized default values:
  --bs-primary-color: #05b1c9;
  --bs-secondary-color: #6c757d;
  --bs-success-color: #28a745;
  --bs-info-color: #2f7798;
  --bs-warning-color: #ffc107;
  --bs-danger-color: #dc3545;
  --bs-light-color: #f8f9fa;
  --bs-dark-color: #343a40;
  // custom style:
  --body-bg-color: #e4e5e6;
  --navbar-color: #fff;
  --navbar-icon-color: #000;
  --navbar-bottom-borderline-color: #05b1c9;
  --text-color: #000;
  --booking-buffer-bg-color: grey;
  --card-bg-color: #fff;
  --card-header-bg-color: #f0f3f5;
  --list-group-item-bg-color: #fff;
}

// p,
// h1,
// h2,
// h3,
// h4,
// a,
// a:active {
//   color: var(--text-color);
// }
// .text-muted {
//   opacity: 0.7;
//   color: var(--text-color);
// }
// body {
//   background-color: var(--body-bg-color);
// }
// .navbar {
//   background-color: var(--navbar-color);
// }
// .nav-mobile-item a,
// .nav-mobile-item i {
//   color: var(--navbar-icon-color);
// }
// .btn,
// .list-group-item.active,
// .btn-primary,
// .btn-outline-primary {
//   background-color: var(--bs-primary-color);
//   border-color: var(--bs-primary-color);
//   color: white;
// }
// .btn:hover,
// .list-group-item.active,
// .btn-primary:hover,
// .btn-outline-primary:hover {
//   background-color: transparent;
//   border-color: var(--bs-secondary-color);
//   color: var(--bs-secondary-color);
// }
// .btn-secondary,
// .btn-outline-secondary {
//   background-color: var(--bs-secondary-color);
//   border-color: var(--bs-secondary-color);
// }
// .btn-success,
// .btn-outline-success {
//   background-color: var(--bs-success-color);
//   border-color: var(--bs-success-color);
// }
// .btn-info,
// .btn-outline-info {
//   background-color: var(--bs-info-color);
//   border-color: var(--bs-info-color);
// }
// .btn-warning,
// .btn-outline-warning {
//   background-color: var(--bs-warning-color);
//   border-color: var(--bs-warning-color);
// }
// .btn-danger,
// .btn-outline-danger {
//   background-color: var(--bs-danger-color);
//   border-color: var(--bs-danger-color);
// }
// .btn-light,
// .btn-outline-light {
//   background-color: var(--bs-light-color);
//   border-color: var(--bs-light-color);
// }
// .btn-dark,
// .btn-outline-dark {
//   background-color: var(--bs-dark-color);
//   border-color: var(--bs-dark-color);
// }
// .btn-link {
//   color: var(--bs-primary-color);
//   background-color: transparent;
//   border-color: transparent;
// }
// .card {
//   background-color: var(--card-bg-color);
// }
// .card .card-header {
//   background-color: var(--card-header-bg-color);
// }
// .list-group-item {
//   background-color: var(--list-group-item-bg-color);
// }

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: var(--text-color);
}
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--bs-primary-color);
  background-color: var(--bs-primary-color);
}

.dropdown-menu .dropdown-item:active {
  background-color: var(--bs-primary-color);
}

.fc-header-toolbar * .btn.active {
  background-color: var(--bs-primary-color);
}
.fc-header-toolbar * .btn:not(.active) {
  background-color: var(--bs-secondary-color);
}
