$blue: #1c2f81;
$cyan: #05b1c9;
$primary: $cyan;
$pink: #d02d74;
$pink2: #d35089;
$red: #f24947;
$yellow: #ffc107;
$green: rgb(62, 177, 102);
$teal: #2f7798;
$info: $teal;
